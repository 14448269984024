<script lang="ts" setup>
import LineChevronRight from '@docue/docue-ui-v2/icons/LineChevronRight.vue'
import LineSearchMd from '@docue/docue-ui-v2/icons/LineSearchMd.vue'
import type { Workspace } from '~/types/auth'

const { t } = useI18n()
const { sortIntl } = useIntl()
const { openModal, isModalOpen } = useModal()
const { activeWorkspaceId, user } = useAuth()

defineExpose({ open: openModal })

const query = ref('')

const { data, isFetching } = useQueryWorkspace.all({
  params: { limit: 250 },
})

const sortedWorkspaces = computed(() => sortIntl(
  data.value?.data || [] as Workspace[],
  ({ attributes }) => attributes.displayName || '',
))

const filteredSortedWorkspaces = computed(() =>
  sortedWorkspaces.value.filter(({ attributes }) =>
    (attributes.displayName || '').toLocaleLowerCase().includes(query.value.toLocaleLowerCase().trim()),
  ),
)

watch(isModalOpen, async (open) => {
  if (!open)
    query.value = ''
})

const selectWorkspace = (workspace: Workspace) => {
  if (workspace.attributes.authRestrictions.requires_mfa && !user.value?.attributes.mfaVerified) {
    location.assign(`/2/enable-mfa?workspaceId=${workspace.id}&return=${encodeURIComponent('/')}`)
  }
  else {
    activeWorkspaceId.value = workspace.id
    location.assign('/')
  }
}

const authMethod = computed(() => user.value?.attributes.authMethod)

const workspaceItems = computed(() => filteredSortedWorkspaces.value.map((workspace) => {
  const isWorkspaceSelectable = workspace.attributes.authRestrictions.methods
    ? authMethod.value && workspace.attributes.authRestrictions.methods.includes(authMethod.value)
    : true

  const getMethodText = (method?: App.Internals.Enums.AuthMethod) => {
    switch (method) {
      case 'google': return t('auth-method.google')
      case 'microsoft': return t('auth-method.microsoft')
      case 'procountor': return t('auth-method.procountor')
      case 'password': return t('auth-method.password')
      default: return undefined
    }
  }

  const getDisabledText = () => {
    if (!workspace.attributes.authRestrictions.methods)
      return undefined

    if (workspace.attributes.authRestrictions.methods?.length > 1) {
      return t('auth-restriction.multiple')
    }
    else {
      switch (workspace.attributes.authRestrictions.methods[0]) {
        case 'google': return t('auth-restriction.google')
        case 'microsoft': return t('auth-restriction.microsoft')
        case 'procountor': return t('auth-restriction.procountor')
        case 'password': return t('auth-restriction.password')
        default: return undefined
      }
    }
  }

  return {
    workspace,
    canceled: !!workspace.attributes.grace_starts_at,
    disabled: !isWorkspaceSelectable,
    disabledText: !isWorkspaceSelectable
      ? getDisabledText()
      : undefined,
    allowedMethods: !isWorkspaceSelectable && (workspace.attributes.authRestrictions.methods?.length ?? 0) > 1
      ? workspace.attributes.authRestrictions.methods?.map(getMethodText)
      : null,
  }
}))
</script>

<template>
  <DTModal
    v-model="isModalOpen"
    overlay-class="bg-black/50"
    container-class="!items-start pt-2 pb-20"
  >
    <DTModalHeader :title="t('choose-workspace.title')" />
    <DTModalBody class="flex flex-col gap-4">
      <div
        v-if="!sortedWorkspaces.length && isFetching"
        class="mx-auto p-4"
      >
        <DTLoaderDots />
      </div>
      <DTFieldInputWrapper v-if="sortedWorkspaces.length > 5">
        <DTFieldInputInlineAddon>
          <LineSearchMd class="w-4 shrink-0 text-gray-800" />
        </DTFieldInputInlineAddon>
        <DTFieldInput
          v-model="query"
          :placeholder="t('choose-workspace.search-placeholder')"
        />
      </DTFieldInputWrapper>
      <div
        class="
            flex
            max-h-[calc(100%-7.75rem)]
            flex-col
            divide-y
            divide-gray-200
            overflow-y-auto
            rounded-lg
            border
            empty:hidden
            "
        :class="{ 'min-h-[8.125rem]': filteredSortedWorkspaces.length > 2 }"
      >
        <DTButton
          v-for="{ workspace, disabled, disabledText, allowedMethods, canceled } in workspaceItems"
          :key="workspace.id"
          :disabled
          class="
              !justify-between
              gap-3
              !rounded-none
              py-4
              text-left
              font-normal
              first-of-type:!rounded-t-lg
              last-of-type:!rounded-b-lg
              "
          variant="plain"
          @click="selectWorkspace(workspace)"
        >
          <div class="flex items-center gap-3">
            <DTAvatar
              size="sm"
              variant="colored"
              :name="workspace.attributes.displayName || '-'"
              :src="workspace.meta?.avatar?.url"
              :alt="workspace.meta?.avatar?.name"
            />
            <div class="flex flex-col">
              {{ workspace.attributes.displayName }}
              <span
                v-if="canceled"
                class="text-xs !text-gray-500"
              >
                {{ capitalize(t('choose-workspace.canceled')) }}
              </span>
              <span
                v-if="disabled"
                class="text-xs text-gray-800"
              >
                {{ disabledText }}
                <ul
                  v-if="allowedMethods"
                  class="pl-6"
                >
                  <li
                    v-for="method in allowedMethods"
                    :key="method"
                    class="list-disc"
                  >{{ method }}</li>
                </ul>
              </span>
            </div>
          </div>
          <span>
            <LineChevronRight class="w-4 shrink-0" />
          </span>
        </DTButton>
      </div>
      <DTButton to="/2/logout">
        {{ t('auth.log-out') }}
      </DTButton>
    </DTModalBody>
  </DTModal>
</template>
